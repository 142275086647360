<template>
	<div class="ele-body">
		<el-card shadow="never">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="客服电话设置" name="first">
					<el-card shadow="never" style="margin-top:20px">
						<el-form :model="editForm" ref="editForm" label-width="150px" style="width:500px"
							:rules="editRules">
							<el-form-item label="省：">
								<el-select v-model="editForm.pname" @change="handleChangeProv1(editForm.pname)"
									placeholder='请选择省' class="selectStyle mr-10" clearable>
									<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="市：">
								<el-select v-model="editForm.cname" @change="handleChangeCity1(editForm.cname)"
									placeholder="请选择市" class="selectStyle mr-10" clearable>
									<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="区/县：">
								<el-select v-model="editForm.aname" @change="$forceUpdate(),handleDis(editForm.aname)"
									placeholder="请选择区/县" class="selectStyle mr-10" clearable>
									<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>
<!--							<el-form-item label="客服电话设置:" prop="phone">-->
<!--								<el-input v-model="editForm.phone" maxlength="11" autocomplete="off" placeholder="请输入手机号" clearable>-->
<!--								</el-input>-->
<!--							</el-form-item>-->
              <el-form-item label="投诉举报热线:" prop="report_phone">
                <el-input v-model="editForm.report_phone" maxlength="18" autocomplete="off" placeholder="请输入座机号" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="产品技术支持:" prop="support_phone">
                <el-input v-model="editForm.support_phone" maxlength="18" autocomplete="off" placeholder="请输入座机号" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="客服主管号码:" prop="service_phone">
                <el-input v-model="editForm.service_phone" maxlength="11" autocomplete="off" placeholder="请输入手机号" clearable>
                </el-input>
              </el-form-item>
							<el-form-item label="客服姓名:" prop="kname">
								<el-input v-model="editForm.kname" autocomplete="off" placeholder="请输入客服姓名" clearable>
								</el-input>
							</el-form-item>
							<el-button type="primary" @click="submit" class="payPrice" v-if="permission.includes('sys:service_guess:submit')">提交</el-button>
						</el-form>
					</el-card>
				</el-tab-pane>

				<el-tab-pane label="客服电话列表" name="second">
					<el-card shadow="never">
						<!-- 搜索表单 -->
						<el-form :model="table.where" ref="table.where" class="ele-form-search"
							style="display:flex;flex-wrap: wrap;" @keyup.enter.native="$refs.table.reload()"
							@submit.native.prevent>
							<el-form-item label="省:" label-width="35px">
								<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
									placeholder='请选择省' class="input150" clearable>
									<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="市:" label-width="35px">
								<el-select v-model="table.where.cid"
									@change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder='请选择市'
									class="input150" clearable>
									<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="县/区:" label-width="65px">
								<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()"
									class="input150 mr-20" clearable>
									<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
									class="ele-btn-icon">搜索</el-button>
								<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
							</el-form-item>
						</el-form>
						<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
							:stripe=true highlight-current-row>
							<template slot-scope="{index}">
								<el-table-column type="selection" width="45" align="center" fixed="left" />
								<el-table-column type="index" :index="index" label="序号" width="60" align="center"
									fixed="left" show-overflow-tooltip />
								<el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="140" />
<!--								<el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="120" />-->
                <el-table-column prop="report_phone" label="投诉举报热线" show-overflow-tooltip min-width="120" />
                <el-table-column prop="support_phone" label="产品技术支持" show-overflow-tooltip min-width="120" />
                <el-table-column prop="service_phone" label="客服主管号码" show-overflow-tooltip min-width="120" />
								<el-table-column prop="kname" label="客服姓名" show-overflow-tooltip min-width="120" />
								<el-table-column prop="gtime" label="更新时间" show-overflow-tooltip min-width="120">
									<template slot-scope="{row}">{{ row.gtime*1000 | toDateString }}</template>
								</el-table-column>
								<el-table-column label="操作" width="200px" align="center" :resizable="false"
									fixed="right">
									<template slot-scope="{row}">
										<el-link icon="el-icon-edit" type="primary" :underline="false"
											@click="edit(row)" v-if="permission.includes('sys:service_guess:edit')">编辑</el-link>
										<el-link icon="el-icon-delete" type="danger" :underline="false"
											@click="remove(row)" v-if="permission.includes('sys:service_guess:delete')">删除</el-link>
									</template>
								</el-table-column>
							</template>
						</ele-data-table>

						<!-- 对话框：客服电话编辑 -->
						<el-dialog v-dialogDrag title="客服电话编辑" :visible.sync="dialogFormVisibleEdit" width="650px"
							:destroy-on-close="true" :lock-scroll="false">
							<el-form :model="editForm" ref="editForm" label-width="140px" :rules="editRules">
								<el-card shadow="never">
									<el-form-item label="所属区域:" prop="set_area">
										<el-input v-model="editForm.set_area" :disabled="true"></el-input>
									</el-form-item>
<!--									<el-form-item label="客服电话:" prop="phone">-->
<!--										<el-input v-model="editForm.phone"></el-input>-->
<!--									</el-form-item>-->
                  <el-form-item label="投诉举报热线:" prop="report_phone">
                    <el-input v-model="editForm.report_phone" maxlength="18" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="产品技术支持:" prop="support_phone">
                    <el-input v-model="editForm.support_phone" maxlength="18" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="客服主管号码:" prop="service_phone">
                    <el-input v-model="editForm.service_phone" maxlength="11" autocomplete="off"></el-input>
                  </el-form-item>
									<el-form-item label="客服姓名:" prop="kname">
										<el-input v-model="editForm.kname" autocomplete="off"></el-input>
									</el-form-item>
								</el-card>
							</el-form>
							<div slot="footer">
								<el-button @click="dialogFormVisibleEdit=false">取消</el-button>
								<el-button type="primary" @click="submit(editForm.gtime)">提交</el-button>
							</div>
						</el-dialog>
					</el-card>
				</el-tab-pane>
			</el-tabs>
		</el-card>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		name: "SysUser",
		data() {
			return {
				table: {
					url: 'customer/customer_list',
					where: {}
				},
				list: [],
				choose: [], // 表格选中数据
				editForm: {}, // 表单数据
				checkForm: {},
				provArr: [],
				cityArr: [],
				districtArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				activeName: 'first',
				dialogFormVisibleEdit: false,
				changePro: false,
				row: [{
						state: '开启'
					},
					{
						state: '关闭'
					}
				],
				editRules: { // 表单验证规则
					aid: [{
						required: true,
						message: '请选择所属区域',
						trigger: "blur"
					}],
					// phone: [{
					// 	required: true,
					// 	message: '请输入客服电话',
					// 	trigger: "blur"
					// }, {
					// 	pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
					// 	message: '请输入正确的手机号',
					// 	trigger: 'blur',
					// }],
          report_phone: [{
            required: true,
            message: '请输入投诉举报热线',
            trigger: "blur"
          }, {
            pattern: /[0-9-()（）]{1,18}/,
            message: '请输入座机号',
            trigger: 'blur',
          }],
          support_phone: [{
            required: true,
            message: '请输入产品技术支持',
            trigger: "blur"
          }, {
            pattern: /[0-9-()（）]{1,18}/,
            message: '请输入座机号',
            trigger: 'blur',
          }],
          service_phone: [{
            required: true,
            message: '请输入客服主管号码',
            trigger: "blur"
          }, {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号',
            trigger: 'blur',
          }],
					kname: [{
						required: true,
						message: '请输入客服姓名',
						trigger: 'blur'
					}, {
            pattern: /[\u4e00-\u9fa5]/,
            message: '请输入正确的客服姓名',
            trigger: 'blur',
          }]
				},
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
		},

		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {},
		methods: {
			edit(row) {
				this.dialogFormVisibleEdit = true
				this.editForm = Object.assign({}, row);
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
					this.editForm.cid = ''
					this.editForm.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aid = ''
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
					this.editForm.pid = ''
					this.editForm.aid = ''
				}
			},

			/**
			 *修改省
			 **/
			handleChangeProv1(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				let that = this
				this.changePro = false
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					that.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cname = ''
					this.editForm.aname = ''
				})
			},
			/**
			 *修改市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.changePro = false
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''
				})
				}else{
					this.editForm.pname = ''
					this.editForm.aname = ''
				}
			},
			handleDis() {
				this.changePro = false
			},
			remove(row) {
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					// let ids = this.choose.map(d => d.id);
					this.$confirm('确定要删除选中的客服电话吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/customer/customer_delete', {
							aid: row.aid
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					this.$confirm('确定要删除选中的客服电话吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/customer/customer_delete', {
							aid: row.aid
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			},
			submit(gtime) {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						if (this.editForm.aname) {
							this.editForm.aid = this.editForm.aname
						}
						if (this.editForm.aid) {
							const loading = this.$loading({
								lock: true
							});
							this.$http.post('/customer/customer_save', {
								kname: this.editForm.kname,
                report_phone: this.editForm.report_phone,
                support_phone: this.editForm.support_phone,
                service_phone: this.editForm.service_phone,
								aid: this.editForm.aid
							}).then(res => {
								loading.close();
								if (res.data.code === 0) {
									this.$message({
										type: 'success',
										message: res.data.msg
									});
									this.$refs.table.reload()
									this.dialogFormVisibleEdit = false
									this.editForm={} //情况表格提交数据
								} else {
									this.$message.error(res.data.msg);
								}
							}).catch(e => {
								loading.close();
								this.$message.error(e.message);
							});
						} else {
							this.$message.error("请选择省市区");
						}

					} else {
						return false;
					}
				});
			},
		}
	}
</script>

<style scoped>
	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.changeStyle {
		width: 150px;
		margin-right: 30px;
	}

	.ele-body {
		padding: 15px;
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}

	/deep/.el-tabs__item {
		padding: 0 20px !important;
		font-size: 15px;
	}

	/deep/.el-tabs__nav-scroll {
		background: white !important;
		padding-top: 10px;
	}

	.drop-down-first {
		width: 150px;
		margin-right: 10px;
	}

	.drop-down-second {
		width: 150px;
	}

	.search-margin-left {
		margin-left: 10px;
	}

	.boxTitle {
		padding: 8px
	}

	.orderImgBox {
		margin-left: 10px;
		height: 80px;
	}

	.orderImgBox span {
		font-size: 36px;
	}

	.user-info-tabs>>>.el-tabs__nav-wrap {
		padding-left: 20px;
	}

	.innerBox {
		width: 60%;
		margin: 0 auto;
		padding-top: 20px;
		border-bottom: 1px rgb(240, 240, 240) solid;
	}

	.innerBoxTo {
		width: 60%;
		margin: 0 auto;
		padding-top: 20px;
	}

	.innerTitle {
		font-size: 16px;
		margin-bottom: 20px;
		margin-left: 13px;
	}

	.inputWidth {
		width: 100%;
	}
</style>
